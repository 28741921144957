import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row, Container } from 'react-bootstrap'
import MediaQuery from "react-responsive"
import ContactForm from './ContactUsForm'
import ContactIcon from './ContactUsIcons'
import FAQ from './Faq'
import "../Layout.css"

const Contact = () => {
    const contentfulData = useStaticQuery(graphql`
        query ContactImageAssets {
            allContentfulContactFaq {
                edges {
                  node {
                    headingText
                    leftImage {
                      file {
                        url
                      }
                    }
                  }
                }
              }
              allContentfulContactInfo {
                edges {
                  node {
                    headingText
                    mobileImage {
                      file {
                        url
                      }
                    }
                    rightImage {
                      file {
                        url
                      }
                    }
                  }
                }
              }
        }
    `)
    return (
        <Container fluid style={{ marginTop: "7%" }}>
          <MediaQuery minWidth={480}>
            <Row className="contact-page-margin">
                <Col md={12}>
                    <p className="contact-header-title">{contentfulData.allContentfulContactInfo.edges[0].node.headingText}</p>
                </Col>
                <Col xl={5} lg={4} md={3}>
                    <ContactForm />
                </Col>
                <Col lg={5} md={5} className="contact-icons-margin">
                    <ContactIcon />
                </Col>
                <Col lg={2} md={3}>
                  <img 
                      src={contentfulData.allContentfulContactInfo.edges[0].node.rightImage.file.url} 
                      alt="Dummy Image"
                      width="100%"
                      className="contact-page-image"
                    />
                </Col>
              </Row>
              <Row style={{ marginTop:"3%", marginLeft: "10%", marginRight:"10%", marginBottom:"3%" }}>
                <Col lg={2} md={3}></Col>
                <Col lg={10} md={9} sm={12} xs={12}>
                    <p className="contact-header-title">{contentfulData.allContentfulContactFaq.edges[0].node.headingText}</p>
                </Col>

                <Col lg={2} md={3}>
                  <img 
                      src={contentfulData.allContentfulContactFaq.edges[0].node.leftImage.file.url} 
                      alt="Dummy Image"
                      width="100%"
                      className="contact-page-image"
                    />
                </Col>
                <Col  lg={9} md={8} sm={12} xs={12}>
                    <FAQ />
                </Col>
            </Row>
            </MediaQuery>
            <MediaQuery maxWidth={479}>
              <Row>
                <img 
                  src={contentfulData.allContentfulContactInfo.edges[0].node.mobileImage.file.url} 
                  alt="Dummy Image"
                  width="100%"
                />
              </Row>
              <Row>
                <Col sm={12} xs={12}>
                    <p className="contact-header-title">{contentfulData.allContentfulContactInfo.edges[0].node.headingText}</p>
                </Col>
                <Col sm={12} xs={12} className="contact-us-icon-container">
                  <ContactIcon />
                </Col>
                <Col sm={12} xs={12}>
                  <ContactForm />
                </Col>
              </Row>
              <Row style={{ marginTop:"3%" }}>
                <div style={{  marginLeft:"5%", marginRight: "5%", marginBottom:"5%" }}>
                <Col md={3}></Col>
                <Col md={9} sm={12} xs={12}>
                    <p className="contact-header-title">{contentfulData.allContentfulContactFaq.edges[0].node.headingText}</p>
                </Col>

                <Col md={1}></Col>
                <Col md={2}>
                    <img 
                        src={contentfulData.allContentfulContactFaq.edges[0].node.leftImage.file.url} 
                        alt="Dummy Image"
                        width="65%"
                        className="contact-page-image"
                    />
                </Col>
                <Col  md={9} sm={12} xs={12}>
                    <FAQ />
                </Col>
              </div>
            </Row>
            </MediaQuery>
        </Container>
    )
}

export default Contact