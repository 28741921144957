import React from "react"
import MediaQuery from "react-responsive"
import { Form, Col, Button } from "react-bootstrap"

import "../Layout.css"

const contactUsForm = () => {

    return (
        <div className="contact-form-spacing">
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formFirstName">
                        <Form.Label className="contact-form-label">First Name</Form.Label>
                        <Form.Control 
                            style={{backgroundColor:"lightgrey", border:"3px #545389 solid"}}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formLastName">
                        <Form.Label className="contact-form-label">Last Name</Form.Label>
                        <Form.Control 
                            style={{backgroundColor:"lightgrey", border:"3px #545389 solid"}}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formEmail">
                        <Form.Label className="contact-form-label">Email</Form.Label>
                        <Form.Control 
                            type="email"
                            style={{backgroundColor:"lightgrey", border:"3px #545389 solid"}}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formLastName">
                        <Form.Label className="contact-form-label">Phone</Form.Label>
                        <Form.Control 
                            type="phone"
                            style={{backgroundColor:"lightgrey", border:"3px #545389 solid"}}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="contact-form-label">Message</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows="3"
                            style={{backgroundColor:"lightgrey", border:"3px #545389 solid"}}
                        />
                    </Form.Group>
                </Form.Row>
                <Button style={{ width:"100%", backgroundColor: "#545389", border:" 1px white solid", marginTop:"5%" }} type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    )
}
export default contactUsForm