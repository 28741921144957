import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faCircle } from '@fortawesome/free-solid-svg-icons'

const ContactUsIcons = () => {
       
    const contentfulData = useStaticQuery(graphql`
        query IconsQuery {
            allContentfulContactInfo {
                edges {
                    node {
                        addressCaptionText
                        addressTitleText
                        emailCaptionText
                        emailTitleText
                        phoneCaptionText
                        phoneTitleText
                       
                    }
                }
            }
        }
    `)
    const contactData = contentfulData.allContentfulContactInfo.edges[0].node
    
    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <Row className="icon-row-height">
                        <Col xs={2}>
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon style={{ marginTop: "50%" }} icon={faCircle} color="#6D6CA4" size="3x"></FontAwesomeIcon>
                                <FontAwesomeIcon style={{ marginTop: "10%" }} icon={faMapMarkerAlt} inverse transform="shrink-10" size="4x"></FontAwesomeIcon>
                            </span>
                        </Col>
                        <Col className="icon-col-spacing" xs={9}>
                            <p className="custom-icon-title">{contactData.addressTitleText}</p>
                            <p className="custom-icon-caption">{contactData.addressCaptionText}</p>
                        </Col>
                    </Row>
                    <Row className="icon-row-height">
                        <Col xs={2}>
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon style={{ marginTop: "20%" }} icon={faCircle} color="#6D6CA4" size="3x"></FontAwesomeIcon>
                                <FontAwesomeIcon style={{ marginTop: "20%" }} icon={faPhoneAlt} inverse transform="shrink-8" size="3x"></FontAwesomeIcon>
                            </span>
                        </Col>
                        <Col className="icon-col-spacing" xs={9}>
                            <p className="custom-icon-title">{contactData.phoneTitleText}</p>
                            <p className="custom-icon-caption">{contactData.phoneCaptionText}</p>
                        </Col>
                    </Row>
                    <Row className="icon-row-height">
                        <Col xs={2}>
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon style={{ marginTop: "20%" }} icon={faCircle} color="#6D6CA4" size="3x"></FontAwesomeIcon>
                                <FontAwesomeIcon style={{ marginTop: "20%" }} icon={faEnvelope} inverse transform="shrink-8" size="3x"></FontAwesomeIcon>
                            </span>
                        </Col>
                        <Col className="icon-col-spacing" xs={9}>
                            <p className="custom-icon-title">{contactData.emailTitleText}</p>
                            <p className="custom-icon-caption">{contactData.emailCaptionText}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactUsIcons