import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Col, Row } from 'react-bootstrap'

const FAQ = () => {

    const contentfulData = useStaticQuery(graphql`
        query contactFaqQuery {
            allContentfulContactFaq {
                edges {
                    node {
                        answer1text
                        answer2Text
                        answer3Text
                        answer4Text
                        answer5
                        answer6
                        answer7
                        answer8
                        answerColor
                        headingColor
                        headingText
                        question1Text
                        question2Text
                        question3Text
                        question4Text
                        question5
                        question6
                        question7
                        question8
                        questionColor
                        leftImage {
                            file {
                              url
                            }
                          }
                    }
                }
            }
        }
    `)

    const faqData = contentfulData.allContentfulContactFaq.edges[0].node
    return (
        <Container fluid style={{marginTop:"2%"}}>
            <Row>
                <Col xs={12}>
                    <p className="custom-p-question">{faqData.question1Text}</p>
                    <p className="custom-p-answer">{faqData.answer1text}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question2Text}</p>
                    <p className="custom-p-answer">{faqData.answer2Text}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question3Text}</p>
                    <p className="custom-p-answer">{faqData.answer3Text}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question4Text}</p>
                    <p className="custom-p-answer">{faqData.answer4Text}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question5}</p>
                    <p className="custom-p-answer">{faqData.answer5}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question6}</p>
                    <p className="custom-p-answer">{faqData.answer6}</p>
                    <br />
                    <p className="custom-p-question">{faqData.question7}</p>
                    <p className="custom-p-answer">{faqData.answer7}</p>
                    <br/>
                    <p className="custom-p-question">{faqData.question8}</p>
                    <p className="custom-p-answer">{faqData.answer8}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default FAQ